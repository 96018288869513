<div class="page-container">
  <div class="d-flex justify-content-end mb-2">
    <app-state-filter-dropdown [color]="'primary'" (stateChange)="filterByState($event)"></app-state-filter-dropdown>
  </div>

  <div class="row mt-2">
    <div class="col-lg-8">
      @if(selectedStates.length &&
        statesWithNexusAndAutoFilling.length === 0 &&
        statesWithNexusAndManualFilling.length === 0 &&
        statesWithoutNexus.length === 0
      ) {
        <div class="card text-center">
          <div class="card-body">
            <br>
            <span class="mdi mdi-information mdi-72px text-primary"></span>
            <br>
            <h5 class="fw-bold">No data available</h5>
            <p class="small">Please try modifying your filters or clear them to view all available states</p>
          </div>
        </div>
      }@else {
        <article *ngIf="statesWithNexusAndAutoFilling.length > 0" id="collecting">
          <h5 class="pb-3 fw-bold">States collecting in & Enrolled in auto filling</h5>
  
          <app-state-tax-card *ngFor="let state of statesWithNexusAndAutoFilling" [collecting]="true"
            [report]="state"></app-state-tax-card>
        </article>
  
        <article *ngIf="statesWithNexusAndManualFilling.length > 0" id="collecting-manual-filling">
          <h5 class="pb-3 fw-bold">States collecting in & filing manually</h5>
  
          <app-state-tax-card *ngFor="let state of statesWithNexusAndManualFilling" [collecting]="true"
            [report]="state"></app-state-tax-card>
        </article>
  
        <br>
  
        <article *ngIf="statesWithoutNexus.length > 0" id="not-collecting">
          <h5 class="pb-3 fw-bold">States were you may have nexus & you have sales transactions</h5>
  
          <div class="card card-header">
            <h5>Steps to get compliant!</h5>
  
            <ol class="small">
              <li>Confirm you have nexus</li>
              <li>Register to collect (Learn More)</li>
              <li>Setup your linked accounts</li>
            </ol>
          </div>
  
  
          <app-state-tax-card *ngFor="let state of statesWithoutNexus" [collecting]="state.has_nexus"
            [report]="state"></app-state-tax-card>
  
          <a class="small fw-bold" routerLink="/account-settings/state-nexus">Add more states <span
              class="mdi mdi-arrow-right"></span></a>
        </article>
      }
  </div>

    <div class="col-lg-4 pt-5">
      <div class="card">
        <div class="card-header align-items-center">
          <strong>To-dos (0)</strong>
        </div>
        <div class="card-body">
          <p class="text-center small">Congrats, you've completed all your to-do’s! 🎉</p>
        </div>
      </div>

      <div class="card">
        <div class="card-header align-items-center">
          <strong>Economic Nexus Insights</strong>
        </div>
        <div class="card-body">
          <p class="small mb-2">
            See the states where we believe your business has met or is approaching economic nexus thresholds.
          </p>
          <small><a routerLink="/economic-nexus">Check For Economic Nexus</a></small>
        </div>
      </div>

      <div class="card">
        <div class="card-header align-items-center">
          <strong>Reconciliation Report</strong>
        </div>
        <div class="card-body">
          <p class="small mb-2">
            Download a comprehensive summary of your monthly sales tax data for your records.
          </p>
          <small><a appDownloadFile [url]="reconReportURL" href="javascript:void()"
              fileName="atomictax_reconciliation_report_{{salesTaxReport.summary.date.to | isoDate:'MM-DD-YYYY' | date:'yyyy':'EDT'}}.csv">Export
              Report</a></small>
        </div>
      </div>

      <div class="card">
        <div class="card-header align-items-center">
          <strong>{{salesTaxReport.summary.date.to | isoDate:'MM-DD-YYYY' | date:'yyyy':'EDT'}} Summary (YTD)</strong>
        </div>
        <ul class="small list-group list-group-flush">
          <li class="list-group-item">
            <div class="row">
              <small>Gross Sales: </small>
              <app-amount class="ml-auto fw-bold" [amount]="salesTaxReport.summary.gross"></app-amount>
            </div>
          </li>
          <li class="list-group-item">
            <div class="row">
              <small>Taxed Sales: </small>
              <app-amount class="ml-auto fw-bold"
                [amount]="salesTaxReport.summary.sales_subject_to_sales_tax"></app-amount>
            </div>
          </li>
          <li class="list-group-item">
            <div class="row">
              <small>Sales Tax Collected: </small>
              <app-amount class="ml-auto fw-bold"
                [amount]="salesTaxReport.summary.total_sales_tax_due"></app-amount>
            </div>
          </li>
          <li class="list-group-item">
            <small><a href="javascript:void(0)">Detailed Sales Tax Analysis</a></small>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
