import { Component, Input, OnInit } from '@angular/core';
import { AppConfig } from '@app/app.config';
import { StateCode, STATES } from '@shared/components/address-list/address-list-form.interface';
import { SalesTaxReport, StateReport } from '@shared/meta-data/sales-tax-report.meta';
@Component({
  selector: 'app-insights-board',
  templateUrl: './insights-board.component.html',
})
export class InsightsBoardComponent implements OnInit {

  @Input() salesTaxReport: SalesTaxReport

  statesWithoutNexus: CodeName[] = []
  statesWithNexusAndAutoFilling: CodeName[] = []
  statesWithNexusAndManualFilling: CodeName[] = []
  selectedStates: string[] = [];

  reconReportURL: string = AppConfig.API_URL + '/sales-tax-report/reconciliation/state/export'

  ngOnInit(): void {
    this.initialize()
  }


  initialize() {
    const breakdown = Object.entries(this.salesTaxReport.breakdown).sort(this.#reOrderStatesByTaxesDue)

    for (let [key, entries] of breakdown)
      if (entries.has_nexus) {
        if (entries.enrolled_in_autofilling) {
          this.statesWithNexusAndAutoFilling.push({ name: STATES[key], code: key as StateCode, ...entries })
        } else {
          this.statesWithNexusAndManualFilling.push({ name: STATES[key], code: key as StateCode, ...entries })
        }
      } else {
        this.statesWithoutNexus.push({ name: STATES[key], code: key as StateCode, ...entries })
      }
  }

  // sort by total sales tax due
  #reOrderStatesByTaxesDue([,a], [,b]): any {
    return b?.reports?.total_sales_tax_due - a?.reports?.total_sales_tax_due;
  }

  filterByState(statesToFilter: string[]){
    this.selectedStates = statesToFilter;

    this.statesWithoutNexus = [];
    this.statesWithNexusAndAutoFilling = [];
    this.statesWithNexusAndManualFilling = [];

    if (!this.salesTaxReport.breakdown) return;

    if(this.selectedStates.length === 0) {
      this.initialize()
      return;
    }

    const breakdown = Object.entries(this.salesTaxReport.breakdown).filter(([key]) => this.selectedStates.includes(key)).sort(this.#reOrderStatesByTaxesDue);
    
    for (let [key, entries] of breakdown)
      if (entries.has_nexus) {
        if (entries.enrolled_in_autofilling) {
          this.statesWithNexusAndAutoFilling.push({ name: STATES[key], code: key as StateCode, ...entries })
        } else {
          this.statesWithNexusAndManualFilling.push({ name: STATES[key], code: key as StateCode, ...entries })
        }
      } else {
        this.statesWithoutNexus.push({ name: STATES[key], code: key as StateCode, ...entries })
      }
  }
}

interface CodeName extends StateReport {
  code: StateCode;
  name: STATES;
}
