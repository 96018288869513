import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GenericItem } from '@shared/meta-data';
import { STATES } from '../address-list/address-list-form.interface';

@Component({
  selector: 'app-state-filter-dropdown',
  templateUrl: './state-filter-dropdown.component.html',
})
export class StateFilterDropdownComponent implements OnInit{
  states: GenericItem[] = [];

  @Input() placeholder: string = 'Filter by State';
  @Input() notFoundText: string = 'No result found';
  @Input() color: 'primary' | 'success' | 'warning' = 'success';

  @Output() stateChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  ngOnInit(): void {
    this.states = Object.entries(STATES).map((item) => ({ id: item[0], name: item[1] }));
  }
  
  onStateChange(selectedStates: string[]): void {
    this.stateChange.emit(selectedStates);
  }
}
