import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { HandleError, HttpErrorHandler } from '@shared/services/error-handler/http-error-handler.service';
import { environment } from '../../../environments/environment';
import { ActiveSubscription, AutoRegisterPayment, ConfirmSubScriptionPayload, IResponse, PaymentHistory, ShopifyPaymentHistory, SubscriptionPlan, SubscriptionStartPayload, Products, AutoFilingPayment, SubscriptionUpgradePayload, SubscriptionPaymentResponse } from '@shared/meta-data';
import { AppConfig } from '@app/app.config';
import { StripeCardToken } from '@shared/meta-data/stripe-card-token.meta';
import { PaymentMethod } from '@shared/components/payment/payment.interface';
import { BuyCredit, ConfirmCredit, CreditHistory, CreditOptions } from '@shared/meta-data/credits.meta';


export interface StripeCard {
  number: string;
  exp_month: string;
  exp_year: string;
  cvc: string;
}

@Injectable({
  providedIn: 'root'
})
export class PlanAndBillingService {

  static readonly STRIPE_URL = 'https://api.stripe.com';
  private readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('Plan & Billing');
  }

  /**
   * Call stripe to tokenize card
   */
  tokenizeCard(card: StripeCard): Observable<StripeCardToken> {
    const body = new URLSearchParams();
    body.set('card[number]', card.number);
    body.set('card[cvc]', card.cvc);
    body.set('card[exp_month]', card.exp_month);
    body.set('card[exp_year]', card.exp_year);

    return this.httpClient.post<StripeCardToken>(
      `${PlanAndBillingService.STRIPE_URL}/v1/tokens`,
      body.toString(),
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${environment.STRIPE_PUB_KEY}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      }
    ).pipe(
      catchError(this.handleError('Tokenize Card', null))
    );
  }

  addSubscription(payload: SubscriptionStartPayload): Observable<any> {
    return this.httpClient.post<IResponse<SubscriptionPlan[]>>(`${AppConfig.API_URL}/subscription/start`, payload)
      .pipe(
        catchError(this.handleError('Start subscription', null)),
        map(({ content }) => content)
      );
  }

  addAutoRegisterSubscription(payload: AutoRegisterPayment): Observable<any> {
    return this.httpClient.post<IResponse<SubscriptionPlan[]>>(`${AppConfig.API_URL}/autoregister/pay`, payload)
      .pipe(
        catchError(this.handleError('Start autoregister subscription', null)),
        map(({ content }) => content)
      );
  }

  addAutoFilingSubscription(payload: AutoFilingPayment): Observable<any> {
    return this.httpClient.post<IResponse<SubscriptionPlan[]>>(`${AppConfig.API_URL}/filing/pay`, payload)
      .pipe(
        catchError(this.handleError('Start autofiling subscription', null)),
        map(({ content }) => content)
      );
  }

  cancelSubscription(subscriptionId: number): Observable<ActiveSubscription> {
    return this.httpClient.post<IResponse<ActiveSubscription>>(`${AppConfig.API_URL}/subscription/cancel/${subscriptionId}`, {})
      .pipe(
        map(({ content }) => content),
        catchError(this.handleError('Cancel subscription', null))
      );
  }

  confirmSubscription(payload: ConfirmSubScriptionPayload): Observable<any> {
    return this.httpClient.post<IResponse<ActiveSubscription>>(`${AppConfig.API_URL}/subscription/confirm`, payload)
      .pipe(
        map(({ content }) => content),
        catchError(this.handleError('Confirm subscription', null))
      );
  }

  fetchPaymentMethod(): Observable<PaymentMethod[]> {
    return this.httpClient.get<IResponse<PaymentMethod[]>>(`${AppConfig.API_URL}/payment-methods`)
      .pipe(
        map(({ content }) => content),
        catchError((error: HttpErrorResponse) => {
          // gracefully handle 404 errors
          if (error.status === 404) return of([]);
          throw(error);
        })
      );
  }

  fetchSubscription(type: Products): Observable<Array<ActiveSubscription>> {
    return this.httpClient.get<IResponse<ActiveSubscription>>(`${AppConfig.API_URL}/subscription/${type}`)
      .pipe(
        map(({ content }) => content),
        catchError(this.handleError('Get subscription', null))
      );
  }

  fetchSubscriptionHistory(): Observable<Array<PaymentHistory | ShopifyPaymentHistory>> {
    return this.httpClient.get<IResponse<Array<PaymentHistory>>>(`${AppConfig.API_URL}/subscription/payments`)
      .pipe(
        map(({ content }) => content),
        catchError(this.handleError('Get Payment history', null))
      );
  }

  fetchAutoFilingProducts(): Observable<SubscriptionPlan> {
    return this.httpClient.get<IResponse<SubscriptionPlan>>(`${AppConfig.API_URL}/subscription/products/Filing`)
      .pipe(
        map(({ content }) => content[0]),
        catchError(this.handleError('Fetch Subscription plans', []))
      );
  }

  fetchAutoRegisterProducts(): Observable<SubscriptionPlan> {
    return this.httpClient.get<IResponse<SubscriptionPlan>>(`${AppConfig.API_URL}/subscription/products/Register`)
      .pipe(
        map(({ content }) => content[0]),
        catchError(this.handleError('Fetch Subscription plans', []))
      );
  }

  fetchReportingProducts(): Observable<SubscriptionPlan[]> {
    return this.httpClient.get<IResponse<SubscriptionPlan[]>>(`${AppConfig.API_URL}/subscription/products/Reporting`)
      .pipe(
        map(({ content }) => content),
        catchError(this.handleError('Fetch Subscription plans', []))
      );
  }

  updateSubscriptionPaymentMethod(merchant_subscription_id: number, token: string): Observable<ActiveSubscription> {
    return this.httpClient.put<IResponse<ActiveSubscription>>(`${AppConfig.API_URL}/subscription/update`, {
      merchant_subscription_id: merchant_subscription_id.toString(),
      token
    })
      .pipe(
        map(({ content }) => content),
        catchError(this.handleError('Update payment method', null))
      );
  }

  updateSubscriptionPlan(subscriptionUploadPayload: SubscriptionUpgradePayload): Observable<ActiveSubscription | SubscriptionPaymentResponse> {
    return this.httpClient.put<IResponse<ActiveSubscription>>(`${AppConfig.API_URL}/subscription/update`, subscriptionUploadPayload)
      .pipe(
        map(({ content }) => content),
        catchError(this.handleError('Update subscription plan', null))
      );
  }

  fetchAtomicTaxCreditCosts(): Observable<CreditOptions[]> {
    return this.httpClient.get<IResponse<CreditOptions[]>>(`${AppConfig.API_URL}/credits/costs`)
      .pipe(
        map(({ content }) => {
          return Array.from(
            new Map(content.map(item => [item.cost + '|' + item.credit + '|' + item.currency, item]))
            .values()
          )
        }),
        catchError(this.handleError('Fetch credit costs', []))
      )
  }

  fetchAtomicTaxCredits(): Observable<{ available_credits: number }> {
    return this.httpClient.get<IResponse<any>>(`${AppConfig.API_URL}/credits`)
      .pipe(
        map(({ content }) => content),
        catchError(this.handleError('Fetch credits', null))
      )
  }

  fetchAtomicTaxCreditHistory(): Observable<CreditHistory[]> {
    return this.httpClient.get<IResponse<any>>(`${AppConfig.API_URL}/credits/history`)
      .pipe(
        map(({ content }) => content),
        catchError(this.handleError('Fetch credit history', []))
      )
  }

  buyAtomicTaxCredits(amount: number): Observable<BuyCredit> {
    return this.httpClient.post<IResponse<any>>(`${AppConfig.API_URL}/credits`, { value: amount })
      .pipe(
        map(({ content }) => content),
        catchError(this.handleError('Buy credits', null))
      )
  }

  confirmAtomicTaxCredits(charge_id: string): Observable<ConfirmCredit> {
    return this.httpClient.post<IResponse<any>>(`${AppConfig.API_URL}/credits/confirm`, { provider: 'shopify', charge_id })
      .pipe(
        map(({ content }) => content),
        catchError(this.handleError('Confirm credit', null))
      )
  }
}
