<ng-select
  [multiple]="true"
  [searchable]="true"
  [notFoundText]="notFoundText"
  [placeholder]="placeholder"
  (change)="onStateChange($event)"
>
  @for (state of states; track state) {
    <ng-option [value]="state.id">
        <i class="state-icon-{{ state?.id | stateCodeToName | stringToKebap }} icon-text-{{color}}"></i>
        {{ state.name }}
    </ng-option>
  }
</ng-select>
